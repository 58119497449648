import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import CalendarIcon from '@mui/icons-material/EventOutlined';
import AirplaneIcon from '@mui/icons-material/FlightTakeoffOutlined';
import CheckIcon from '@mui/icons-material/CheckOutlined';
import PieChartIcon from '@mui/icons-material/PieChartOutlined';
import { AttachMoneyOutlined as DollarIcon } from '@mui/icons-material';
import { useMemo } from 'react';

import {
  ShowConfiguration,
  ShowOperations,
  ViewApprovalsDashboard,
  ViewBillingDashboard,
  ViewCustomersDashboard,
} from 'domain/types/Roles';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { isNonNullish } from 'infrastructure/utils/isNonNullish';
import { NavigationItem, NavigationLink } from 'targets/web/components/Navigation';
import { jobsRoutes } from 'targets/web/modules/jobs/navigation/jobsRoutes';
import { approvalsRoutesConfig } from 'targets/web/modules/approvals/navigation/approvalsRoutesConfig';

interface AccessLimitedNavigationLink extends NavigationLink {
  requireFeatureConfig?: string;
}

type AccessLimitedNavigationItem = Omit<NavigationItem, 'nested'> & {
  nested?: AccessLimitedNavigationLink[];
};

export const useAvailableNavigationLinks = (): AccessLimitedNavigationItem[] => {
  const t = useTranslationPrefix('global.navigation');

  const { hasRole } = useCaseCheckAccess();

  return useMemo(() => {
    return [
      hasRole(ViewApprovalsDashboard)
        ? {
            url: approvalsRoutesConfig.main,
            icon: <CheckIcon />,
            label: t('approve_and_decline'),
          }
        : null,
      {
        url: jobsRoutes.jobs,
        icon: <AirplaneIcon />,
        label: t('jobs_and_services'),
      },
      {
        url: 'schedule',
        icon: <CalendarIcon />,
        label: t('schedule'),
      },

      hasRole(ShowOperations)
        ? {
            url: 'operations',
            icon: <PieChartIcon />,
            label: t('operations'),
          }
        : null,
      hasRole(ViewBillingDashboard)
        ? {
            url: 'billing',
            icon: <DollarIcon />,
            label: t('billing'),
            bottom: true,
          }
        : null,
      hasRole(ShowConfiguration)
        ? {
            url: 'configuration',
            icon: <SettingsIcon />,
            label: t('configuration'),
            bottom: true,
            nested: [
              {
                url: `configuration/aircrafts`,
                label: t('aircrafts'),
              },
              hasRole(ViewCustomersDashboard)
                ? {
                    url: `configuration/customers`,
                    label: t('customers'),
                  }
                : null,
              {
                url: `configuration/stations`,
                label: t('stations'),
              },
              {
                url: `configuration/service-types`,
                label: t('services'),
              },
              {
                url: `configuration/price-lists`,
                label: t('price_lists'),
              },
              {
                url: `configuration/aircraft-types`,
                label: t('aircraft_types'),
              },
            ].filter(isNonNullish),
          }
        : null,
    ].filter(isNonNullish);
  }, [hasRole, t]);
};
